@import 'reset';
@import 'buttons';

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700&family=Noto+Sans:wght@300;400;500;600;700&display=swap');
@import '../fontawasome/css/all.min.css';

:root {
	--font-title: 'Dosis', sans-serif;

	--site-main-color: #7b68ee;

	--section-padding: 100px 0;

	--section-highlight-color: #ff9800;
	--section-highlight-size: 18px;
	--section-highlight-weight: 700;

	--section-title-color: #0b0b31;
	--section-title-size: 38px;
	--section-title-weight: 700;
	--section-title-elevation: 20px;

	--footer-background: #1c1c23;
	--footer-copyright-background: #212129;
	--footer-primary-padding: 100px 0;
	--footer-secondary-padding: 50px 0;
}

html,
body {
	height: 100%;
	min-height: 100%;
}

body {
	font-family: 'Noto Sans', sans-serif;
}

.container {
	width: 100%;
	max-width: 1200px;
	padding-left: 40px;
	padding-right: 40px;
	margin-right: auto;
	margin-left: auto;
	@media (max-width: 1200px) {
		max-width: 992px;
	}
	@media (max-width: 992px) {
		max-width: 768px;
	}
	@media (max-width: 768px) {
		max-width: 556px;
	}
}
