article {
	&.blog {
		box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
		border-radius: 4px;
		overflow: hidden;
		--gap: 50px;
		--columns: 3;
		width: calc(
			(100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns))
		);
		@media (max-width: 992px) {
			--columns: 2;
		}
		@media (max-width: 768px) {
			--columns: 1;
		}
		.blog-thumbnail {
			span {
				width: 100%;
				img {
					width: 100% !important;
				}
			}
		}
		.blog-items {
			padding: 20px 30px;
			display: flex;
			flex-direction: column;
			gap: 15px 0;
			.blog-created {
				display: flex;
				justify-content: space-between;
				.blog-item {
					a {
						display: flex;
						align-items: center;
						color: #7b68ee;
						font-family: var(--font-title);
						gap: 0 10px;
						svg {
							font-size: 14px;
						}
						span {
							font-weight: 500;
						}
					}
				}
			}
			.blog-title {
				a {
					font-family: var(--font-title);
					font-weight: 700;
					color: #0b0b31;
					font-size: 20px;
					transition: .35s;
					&:hover {
						color: var(--site-main-color);
					}
				}
			}
			.blog-desc {
				p {
					line-height: 25px;
					font-size: 14px;
					color: rgba(#000, 0.6);
				}
			}
			.blog-details {
				a {
					color: #7b68ee;
					font-family: var(--font-title);
					font-weight: 600;
					text-decoration: underline;
				}
			}
		}
	}
}
