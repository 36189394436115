a.contact-button,
button.contact-button {
	background: #3e57d0;
	color: #fff;
	font-weight: 600;
	font-family: var(--font-title);
	border-radius: 4px;
	box-shadow: 0 7px 25px rgb(123 104 238 / 25%);
	padding: 12px 30px;
	font-size: 15px;
    cursor: pointer;
}
