@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,500;0,700;0,900;1,300;1,400&display=swap');


.markdown-body {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	color: #24292e;
	line-height: 1.5;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	line-height: 1.5;
	word-wrap: break-word;
	margin-top: 40px;
}

.markdown-body > *:first-child {
	margin-top: 0 !important;
}

.markdown-body > *:last-child {
	margin-bottom: 0 !important;
}

.markdown-body a {
	color: #4183c4;
	text-decoration: none;
}

.markdown-body a.absent {
	color: #cc0000;
}

.markdown-body a.anchor {
	display: block;
	padding-left: 30px;
	margin-left: -30px;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
	margin: 20px 0 10px;
	padding: 0;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
	cursor: text;
	position: relative;
}

.markdown-body h3 {
	color: #ff9800;
	display: flex;
	align-items: center;
	gap: 0 20px;
}

.markdown-body h3::before {
	content: '';
	display: block;
	background: #ff9800;
	width: 25px;
	height: 2px;
	border-radius: 100%;
}

.markdown-body h2:first-child,
.markdown-body h1:first-child,
.markdown-body h1:first-child + h2,
.markdown-body h3:first-child,
.markdown-body h4:first-child,
.markdown-body h5:first-child,
.markdown-body h6:first-child {
	margin-top: 0;
	padding-top: 0;
}

.markdown-body h1:hover a.anchor,
.markdown-body h2:hover a.anchor,
.markdown-body h3:hover a.anchor,
.markdown-body h4:hover a.anchor,
.markdown-body h5:hover a.anchor,
.markdown-body h6:hover a.anchor {
	text-decoration: none;
}

.markdown-body h1 tt,
.markdown-body h1 code {
	font-size: inherit;
}

.markdown-body h2 tt,
.markdown-body h2 code {
	font-size: inherit;
}

.markdown-body h3 tt,
.markdown-body h3 code {
	font-size: inherit;
}

.markdown-body h4 tt,
.markdown-body h4 code {
	font-size: inherit;
}

.markdown-body h5 tt,
.markdown-body h5 code {
	font-size: inherit;
}

.markdown-body h6 tt,
.markdown-body h6 code {
	font-size: inherit;
}

.markdown-body h1 {
	font-size: 28px;
	color: #041d38;
}

.markdown-body h2 {
	font-size: 24px;
	color: #041d38;
}

.markdown-body h3 {
	font-size: 18px;
}

.markdown-body h4 {
	font-size: 16px;
	color: #041d38;
}

.markdown-body h5 {
	font-size: 14px;
	color: #041d38;
}

.markdown-body h6 {
	color: #777777;
	font-size: 14px;
}

.markdown-body p,
.markdown-body blockquote,
.markdown-body ul,
.markdown-body ol,
.markdown-body dl,
.markdown-body li,
.markdown-body table,
.markdown-body pre {
	margin: 15px 0;
}

.markdown-body hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	border-bottom: 1px solid #cccccc;
}

.markdown-body body > h2:first-child {
	margin-top: 0;
	padding-top: 0;
}

.markdown-body body > h1:first-child {
	margin-top: 0;
	padding-top: 0;
}

.markdown-body body > h1:first-child + h2 {
	margin-top: 0;
	padding-top: 0;
}

.markdown-body body > h3:first-child,
.markdown-body body > h4:first-child,
.markdown-body body > h5:first-child,
.markdown-body body > h6:first-child {
	margin-top: 0;
	padding-top: 0;
}

.markdown-body a:first-child h1,
.markdown-body a:first-child h2,
.markdown-body a:first-child h3,
.markdown-body a:first-child h4,
.markdown-body a:first-child h5,
.markdown-body a:first-child h6 {
	margin-top: 0;
	padding-top: 0;
}

.markdown-body h1 p,
.markdown-body h2 p,
.markdown-body h3 p,
.markdown-body h4 p,
.markdown-body h5 p,
.markdown-body h6 p {
	margin-top: 0;
}

.markdown-body li p.first {
	display: inline-block;
}

.markdown-body ul,
.markdown-body ol {
	padding-left: 30px;
}

.markdown-body ul :first-child,
.markdown-body ol :first-child {
	margin-top: 0;
}

.markdown-body ul :last-child,
.markdown-body ol :last-child {
	margin-bottom: 0;
}

.markdown-body dl {
	padding: 0;
}

.markdown-body dl dt {
	font-size: 14px;
	font-weight: bold;
	font-style: italic;
	padding: 0;
	margin: 15px 0 5px;
}

.markdown-body dl dt:first-child {
	padding: 0;
}

.markdown-body dl dt > :first-child {
	margin-top: 0;
}

.markdown-body dl dt > :last-child {
	margin-bottom: 0;
}

.markdown-body dl dd {
	margin: 0 0 15px;
	padding: 0 15px;
}

.markdown-body dl dd > :first-child {
	margin-top: 0;
}

.markdown-body dl dd > :last-child {
	margin-bottom: 0;
}

.markdown-body blockquote {
	border-left: 4px solid #dddddd;
	padding: 0 15px;
	color: #777777;
}

.markdown-body blockquote > :first-child {
	margin-top: 0;
}

.markdown-body blockquote > :last-child {
	margin-bottom: 0;
}

.markdown-body table {
	padding: 0;
	border-collapse: collapse;
}

.markdown-body table tr {
	border-top: 1px solid #cccccc;
	background-color: white;
	margin: 0;
	padding: 0;
}

.markdown-body table tr:nth-child(2n) {
	background-color: #f8f8f8;
}

.markdown-body table tr th {
	font-weight: bold;
	border: 1px solid #cccccc;
	text-align: left;
	margin: 0;
	padding: 6px 13px;
}

.markdown-body table tr td {
	border: 1px solid #cccccc;
	text-align: left;
	margin: 0;
	padding: 6px 13px;
}

.markdown-body table tr th :first-child,
.markdown-body table tr td :first-child {
	margin-top: 0;
}

.markdown-body table tr th :last-child,
.markdown-body table tr td :last-child {
	margin-bottom: 0;
}

.markdown-body img {
	max-width: 100%;
}

.markdown-body span.frame {
	display: block;
	overflow: hidden;
}

.markdown-body span.frame > span {
	border: 1px solid #dddddd;
	display: block;
	float: left;
	overflow: hidden;
	margin: 13px 0 0;
	padding: 7px;
	width: auto;
}

.markdown-body span.frame span img {
	display: block;
	float: left;
}

.markdown-body span.frame span span {
	clear: both;
	color: #333333;
	display: block;
	padding: 5px 0 0;
}

.markdown-body span.align-center {
	display: block;
	overflow: hidden;
	clear: both;
}

.markdown-body span.align-center > span {
	display: block;
	overflow: hidden;
	margin: 13px auto 0;
	text-align: center;
}

.markdown-body span.align-center span img {
	margin: 0 auto;
	text-align: center;
}

.markdown-body span.align-right {
	display: block;
	overflow: hidden;
	clear: both;
}

.markdown-body span.align-right > span {
	display: block;
	overflow: hidden;
	margin: 13px 0 0;
	text-align: right;
}

.markdown-body span.align-right span img {
	margin: 0;
	text-align: right;
}

.markdown-body span.float-left {
	display: block;
	margin-right: 13px;
	overflow: hidden;
	float: left;
}

.markdown-body span.float-left span {
	margin: 13px 0 0;
}

.markdown-body span.float-right {
	display: block;
	margin-left: 13px;
	overflow: hidden;
	float: right;
}

.markdown-body span.float-right > span {
	display: block;
	overflow: hidden;
	margin: 13px auto 0;
	text-align: right;
}

.markdown-body code,
.markdown-body tt {
	margin: 0 2px;
	padding: 0 5px;
	white-space: nowrap;
	border: 1px solid #eaeaea;
	background-color: #f8f8f8;
	border-radius: 3px;
}

.markdown-body pre code {
	margin: 0;
	padding: 0;
	white-space: pre;
	border: none;
	background: transparent;
}

.markdown-body.highlight pre {
	background-color: #f8f8f8;
	border: 1px solid #cccccc;
	font-size: 13px;
	line-height: 19px;
	overflow: auto;
	padding: 6px 10px;
	border-radius: 3px;
}

.markdown-body pre {
	background-color: #f8f8f8;
	border: 1px solid #cccccc;
	font-size: 13px;
	line-height: 19px;
	overflow: auto;
	padding: 6px 10px;
	border-radius: 3px;
}

.markdown-body pre code,
.markdown-body pre tt {
	background-color: transparent;
	border: none;
}

.markdown-body ul,
.markdown-body ul ul ul {
	list-style-type: disc;
	list-style-position: inside;
}

.markdown-body ul ul ul {
	margin-left: 15px;
}

.markdown-body ol {
	list-style-type: decimal;
	list-style-position: inside;
}

.markdown-body ul ul,
.markdown-body ul ul ul ul,
.markdown-body ol ul {
	list-style-type: circle;
	list-style-position: inside;
	margin-left: 15px;
}

.markdown-body ol ol,
.markdown-body ul ol {
	list-style-type: lower-latin;
	list-style-position: inside;
	margin-left: 15px;
}

.markdown-body p strong {
	font-weight: 600;
	font-size: 18px;
}