section.overview {
	padding: 100px 0;
	.container {
		display: flex;
		justify-content: space-between;
		&.ltr {
			flex-direction: row-reverse;
		}
		gap: 0 30px;
		@media (max-width: 992px) {
			flex-direction: column !important;
            gap: 50px 0 !important;
			.overview-contents,
			.overview-image {
				width: 100% !important;
			}
		}
		@media (max-width: 1200px) {
			.overview-contents {
				.overview-items {
					ul {
						li {
							width: 100% !important;
						}
					}
				}
			}
		}
		.overview-image {
			width: 45%;
			img {
				width: 100%;
			}
		}
		.overview-contents {
			width: 55%;
			> h2 {
				color: #ff9800;
				font-family: var(--font-title);
				font-weight: 700;
			}
			> h1 {
				font-size: 38px;
				font-family: var(--font-title);
				font-weight: 700;
				padding: 15px 0;
			}
			> span {
				color: rgba(#000, 0.85);
				font-size: 14px;
				line-height: 25px;
				font-weight: 500;
			}
			.overview-items {
				margin-top: 32px;
				ul {
					display: flex;
					flex-wrap: wrap;
					gap: 20px;
					justify-content: space-between;
					li {
						width: calc(50% - 20px);
						border-radius: 6px;
						border-left: 3px solid #7b68ee;
						padding: 15px 20px;
						box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
						font-family: var(--font-title);
						font-weight: 600;
						position: relative;
						overflow: hidden;
						transition: 0.25s;
						&:hover {
							color: #fff;
							&::after {
								transform: translateX(0);
							}
						}
						&::after {
							transition: 0.35s ease-in-out;
							content: '';
							display: block;
							background: #7b68ee;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							position: absolute;
							z-index: -1;
							transform: translateX(-100%);
						}
					}
				}
			}
		}
	}
}
