footer {
	.footer-subscribe {
		padding: var(--footer-secondary-padding);
		background: var(--footer-background);

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 0 50px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 30px 0 !important;
        .subscribe-input,
        .sub-text {
          width: 100% !important;
        }
      }
			.sub-text {
				display: flex;
				flex-direction: column;
				gap: 10px 50px;
				flex: 1;

				h1 {
					font-family: var(--font-title);
					font-weight: 700;
					font-size: 24px;
					color: #fff;
				}

				p {
					font-size: 15px;
					color: rgba(#fff, 0.8);
				}
			}

			.subscribe-input {
				flex: 1;

				form {
					background: #fff;
					height: 50px;
					border-radius: 6px;
					display: flex;
					overflow: hidden;

					input {
						width: 100%;
						height: 100%;
						padding: 0 20px;
						font-size: 14px;
					}

					button {
						height: 100%;
						padding: 0 50px;
						background: var(--site-main-color);
						cursor: pointer;
						color: #fff;
						display: block;
						font-family: var(--font-title);
						font-weight: 600;
					}
				}
			}
		}
	}

	.footer-elements {
		border-top: 1px solid rgba(#fff, 0.5);
		padding: var(--footer-primary-padding);
		background: var(--footer-background);

		.container {
			--gap: 50px;
			--columns: 4;

			display: flex;
			gap: var(--gap);

			@media (max-width: 1200px) {
				flex-wrap: wrap;
				--columns: 2;
			}

			@media (max-width: 768px) {
				--columns: 1;
			}

			.footer-item {
				width: calc(
					(100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns))
				);

				.item-title {
					margin-bottom: 35px;
					border-bottom: 1px solid rgba(#fff, 0.5);
					padding-bottom: 20px;

					h1 {
						font-family: var(--font-title);
						color: #fff;
						font-weight: 700;
						font-size: 20px;
					}
				}

				.item-desc {
					color: #f1f1f1;
					font-size: 14px;
					line-height: 25px;
					margin-bottom: 35px;
				}

				.items {
					ul {
						li {
							&.more-than {
								display: flex;
								gap: 0 15px;

								.about {
									h1 {
										color: #fff;
										font-family: var(--font-title);
										font-weight: 600;
										font-size: 18px;
									}
								}
							}

							a {
								display: block;
								color: #f1f1f1;
								font-size: 14px;
								padding: 10px 0;
								transition: 0.35s all;

								&:hover {
									color: var(--site-main-color);
								}
							}
						}
					}
				}

				.item-socials {
					ul {
						display: flex;
						gap: 0 10px;

						li {
							a {
								display: flex;
								align-items: center;
								justify-content: center;
								background: #f1f1f1;
								border-radius: 50%;
								width: 35px;
								height: 35px;

								svg {
									transition: 0.35s;
								}

								&:hover {
									svg {
										color: var(--site-main-color);
									}
								}
							}
						}
					}
				}

				&.big-item {
					flex: 2;
				}
			}
		}
	}
	.footer-copyright {
		display: flex;
		justify-content: center;
		padding: var(--footer-secondary-padding);
		background: var(--footer-copyright-background);
		align-items: center;
		gap: 0 20px;
		span {
			color: #fafafa;
			font-size: 15px;
		}
	}
}
