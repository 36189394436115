.hero-background {
	height: 500px;
	width: 100%;
	background-image: url('../../../public/static/img/page-title-bg.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 992px) {
		height: 400px;
	}
	.hero-content {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding-top: 50px;
		gap: 20px 0;
		background: rgba(#000, 0.4);
		.page-name {
			h1 {
				@media (max-width: 992px) {
					font-size: 32px;
				}
				@media (max-width: 556px) {
					font-size: 28px;
				}
				font-family: var(--font-title);
				font-weight: 700;
				font-size: 42px;
				color: #fff;
			}
		}
		.breadcrumbs {
			display: flex;
			align-items: center;
			gap: 0 10px;
			flex-wrap: wrap;
			@media (max-width: 768px) {
				span {
					font-size: 14px;
				}
			}
			span {
				font-family: var(--font-title);
				color: #fafafa;
				font-weight: 600;
			}
		}
	}
}
