.blog-inner {
	width: 800px;
	.blog-image {
		img {
			border-radius: 4px;
			width: 100%;
		}
	}
	.blog-created {
		display: flex;
		align-items: center;
		padding: 20px 0;
		gap: 0 20px;
		.created-item {
			display: flex;
			align-items: center;
			gap: 0 5px;
			h1 {
				font-family: var(--font-title);
				font-weight: 600;
			}
			span {
				color: #5f5f5f;
				font-size: 15px;
			}
		}
	}
	.blog-title {
		h1 {
			font-family: var(--font-title);
			font-weight: 700;
			font-size: 25px;
			padding-bottom: 20px;
		}
	}
	.blog-markdown {
		p {
			line-height: 25px;
			font-size: 14px;
			color: #5f5f5f;
		}
	}
}
