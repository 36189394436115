section#blogs {
	padding: var(--section-padding);
	.container {
		> h1 {
			text-align: center;
			font-family: var(--font-title);
			font-weight: 700;
			font-size: 42px;
			padding-top: 20px;
		}

		> h2 {
			text-align: center;
			color: #ff9800;
			font-weight: 700;
			font-family: var(--font-title);
		}
		.blogs {
			display: flex;
			margin-top: 50px;
			gap: 30px;
			flex-wrap: wrap;
		}
	}
}

.pagination-items {
	display: flex;
	align-items: center;
	gap: 0 15px;
	justify-content: center;
	margin-top: 50px;
	.pagination-item {
		display: block;
		font-size: 14px;
		font-family: var(---font-title);
		padding: 13px 20px;
		font-weight: 700;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		cursor: pointer;
		&.active-page {
			background-color: var(--site-main-color);
			color: #fff;
		}
	}
}
