section#company {
  padding: 100px 0;

  .container {
    display: flex;
    gap: 0 100px;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 50px 0;
      .company-image {
        width: 100% !important;
        order: 1 !important;
      }
      .company-details {
        width: 100% !important;
        order: 2 !important;
        padding: 0 !important;
      }
    }
    .company-details {
      padding-right: 50px;
      width: 55%;
      > a {
        display: inline-block;
        margin-top: 50px;
        background: #7b68ee;
        color: #fff;
        font-family: var(--font-title);
        font-weight: 600;
        border-radius: 6px;
        padding: 13px 40px;
      }
      > h2 {
        font-family: var(--font-title);
        font-weight: 700;
        font-size: 16px;
        color: #7b68ee;
      }

      > h1 {
        font-family: var(--font-title);
        font-weight: 700;
        font-size: 38px;
        padding: 25px 0;
        line-height: 50px;
      }

      > span {
        color: rgba(#000, .9);
        font-size: 14px;
        line-height: 25px;
      }

      .skills {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px 0;
        .skill {
          .skill-detail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h1 {
              font-family: var(--font-title);
              color: #7b68ee;
              font-weight: 700;
            }
            h2 {
              color: #7b68ee;
              font-weight: 700;
              font-size: 14px;
            }
          }
          .percent-bar {
            margin-top: 15px;
            width: 100%;
            height: 5px;
            background: rgba(#7b68ee, .1);
            span {
              display: block;
              height: 100%;
              background: #7b68ee;
            }
          }
        }
      }
    }

    .company-image {
      width: 45%;
      img {
        width: 100%;
      }
    }
  }
}