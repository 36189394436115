.input-primary {
	background-color: #ffffff;
	border: none;
	height: 55px;
	font-size: 15px;
	border-left: 3px solid #7b68ee;
	-webkit-box-shadow: 0px 5px 30px 0px rgba(148, 146, 245, 0.15);
	box-shadow: 0px 5px 30px 0px rgba(148, 146, 245, 0.15);
    padding: 0 20px;
    width: 100%;
    border-radius: 6px;
    &::placeholder {
        color: rgba(#000, .75);
    }
}

.error-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px 0;
    span {
        margin-left: 10px;
        font-size: 13px;
        font-weight: 500;
        color: #A7171A;
    }
}