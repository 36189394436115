.rich-box {
	width: 100%;
	background-color: #ffffff;
	border: none;
	height: 55px;
	font-size: 15px;
	border-left: 3px solid #7b68ee;
	-webkit-box-shadow: 0px 5px 30px 0px rgba(148, 146, 245, 0.15);
	box-shadow: 0px 5px 30px 0px rgba(148, 146, 245, 0.15);
	padding: 20px;
	border-radius: 6px;
    width: 100%;
    height: 200px;
    resize: vertical;
    overflow: hidden;
	&::placeholder {
		color: rgba(#000, 0.75);
	}
}
