.popular-wrapper {
	flex: 1;
	.wrapper-title {
		position: relative;
		h1 {
			font-weight: 700;
			font-size: 22px;
			font-family: var(--font-title);
		}
		padding-bottom: 10px;
		border-bottom: 1px solid #eee;
		margin-bottom: 20px;
	}
	.popular-posts {
		display: flex;
		flex-direction: column;
		gap: 15px 0;
		.popular-post {
			display: flex;
			gap: 0 10px;
			.post-thumbnail {
				cursor: pointer;
                overflow: hidden;
				&:hover {
					img {
						transform: scale(1.1);
                        filter: brightness(.85);
					}
				}
                img {
                    transition: .35s all;
                    width: 80px;
                    height: 80px;
                }
			}
			.post-details {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 5px 0;
				.post-createdAt {
					span {
						color: #5f5f5f;
						font-weight: 500;
						font-size: 13px;
					}
				}
				.post-title {
					a {
						font-family: var(--font-title);
						font-weight: 700;
						transition: 0.15s;
						&:hover {
							color: var(--site-main-color);
						}
					}
				}
			}
		}
	}
}
