section#contact {
    padding: var(--section-padding);
	.container {
		> h2 {
			color: var(--section-highlight-color);
			font-family: var(--font-title);
			font-weight: var(--section-highlight-weight);
			font-size: var(--section-highlight-size);
			text-align: center;
		}
		> h1 {
			color: var(--section-title-color);
			font-family: var(--font-title);
			font-weight: var(--section-title-weight);
			font-size: var(--section-title-size);
			text-align: center;
			padding-top: var(--section-title-elevation);
		}
		.contact-form {
			margin-top: 50px;
			.input-group {
				display: flex;
				flex-wrap: wrap;
				gap: 20px;
				.form-element {
					width: calc(50% - 10px);
					@media (max-width: 768px) {
						width: 100% !important;
					}
					&.element-full {
						width: 100%;
					}
				}
			}
		}
	}
}
