section#content {
	padding: var(--section-padding);
	.container {
		> h1 {
			text-align: center;
			font-family: var(--font-title);
			font-weight: 700;
			font-size: 42px;
			padding-top: 20px;
			@media (max-width: 768px) {
				font-size: 32px;
			}
		}

		> h2 {
			text-align: center;
			color: #ff9800;
			font-weight: 700;
			font-family: var(--font-title);
		}
		hr {
			display: block;
			width: 100px;
			background-color: #333;
			height: 3px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 30px;
			margin-top: 10px;
		}
	}
}
